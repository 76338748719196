import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMsal, useIsAuthenticated, useAccount } from "@azure/msal-react";

// import GoogleLoginButton from "./GoogleLoginButton";
// import AzureLoginButton from "./AzureLoginButton";

import "./LoginPanel.css";

const LoginPanel = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(instance.getActiveAccount());

  // State to store email and password
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState("");

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.email === "" || formData.password === "") {
      setError("Please fill in both fields");
    } else {
      setError("");
      console.log("Logging in:", formData);
      // Place your login logic here, e.g., API call
    }
  };

  const handleLogout = () => {
    instance.logoutPopup();
  };

  // TODO remove in production
  if (isAuthenticated) {
    console.log(account);
  }

  return (
    <div className="login-panel">
      <svg className="login-svg">
        <defs>
          <filter id="f1" x="-10%" y="-10%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="15" />
          </filter>
          <filter id="f2" x="-10%" y="-10%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="25" />
          </filter>
        </defs>
        <circle
          cx="12%"
          cy="194%"
          r="1000"
          stroke="#dfc40320"
          strokeWidth={80}
          fill="none"
          filter="url(#f1)"
        />
      </svg>
      {isAuthenticated ? (
        <div className="login-box authenticated">
          <h1>Welcome, {account ? account.name : "User"}!</h1>
          <p>
            Logged in as <b>{account ? account.username : "null"}</b>
          </p>
          <button className="logout" onClick={handleLogout}>
            Sign Out
          </button>
        </div>
      ) : (
        <div className="login-box">
          <h1>Log In</h1>
          {/* <GoogleLoginButton />
          <AzureLoginButton /> */}
          {error && <p className="error">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </div>
            <button type="submit">Login</button>
          </form>
          <p className="register-link">
            Don’t have an account? <Link to="/register">Sign up here</Link>
          </p>
        </div>
      )}
    </div>
  );
};

export default LoginPanel;
