import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "3ef518dc-34fd-49bc-8ceb-d520fb787d12", // Replace with your application's client ID
    authority:
      "https://login.microsoftonline.com/53643668-8629-4277-a9b4-38d4abfbb3fa", // Or specify your tenant ID
    redirectUri: "http://localhost:3000", // Replace with your redirect URI
  },
  cache: {
    cacheLocation: "sessionStorage", // Or "localStorage" based on your app's needs
    storeAuthStateInCookie: false, // Set to "true" if you're facing issues on IE11 or Edge
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
