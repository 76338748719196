import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/Navbar/Navbar";
import LoginPanel from "../../components/LoginPanel/LoginPanel";
import Footer from "../../components/Footer/Footer";

import "./Login.css";

export const Login = () => {
  const headerRef = useRef(null);
  const location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]);
  return (
    <div className={`login ${t("language")}`}>
      <div ref={headerRef} className="header">
        <Navbar />
      </div>
      <span style={{ height: "99px", width: "1px", display: "block" }} />
      <LoginPanel />
      <Footer />
    </div>
  );
};
