import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import logo from "../../images/logo.svg";
// LANGUAGE
import greeceFlag from "../../images/greece.png";
import ukFlag from "../../images/uk.png";

import "./Navbar.css";

import NavbarOptions from "./NavbarOptions";
import Burger from "./Burger";
import { t } from "i18next";

const Navbar = () => {
  return (
    <div className="navbar">
      <Link to="/" className="navbar-logo">
        <img src={logo} alt="" className="navbar-logo-image"></img>
      </Link>
      <NavbarOptions type="navbar" />
      <NavbarLanguage />
      <div className="navbar-rightside">
        <Link className="navbar-login" to="/login">
          {t("navLogin")}
        </Link>
        <Burger />
      </div>
    </div>
  );
};

// LANGUAGE
const NavbarLanguage = () => {
  const { t, i18n } = useTranslation();
  const handleLanguageChange = (value) => {
    console.log(value);
    i18n.changeLanguage(value);
  };

  // const [languageIsOpen, setLanguageIsOpen] = useState(false);
  // const [selectedLanguage, setSelectedLanguage] = useState({
  //   value: "en",
  //   image: ukFlag,
  // });

  // let languageOptions = [
  //   { value: "en", label: "English", image: ukFlag },
  //   { value: "gr", label: "Ελληνικά", image: greeceFlag },
  // ];

  return (
    <div className="navbar-language">
      <div
        className="select-button"
        onClick={() => handleLanguageChange(t("otherLanguage"))}
      >
        <img src={t("language") === "el" ? ukFlag : greeceFlag} alt="" />
        {/* <div className="dropdown-arrow" id="language">
          <span className="arrow-1" />
          <span className="arrow-2" />
        </div> */}
      </div>
      {/* <div className="dropdown-content">
        {languageOptions.map((language) => (
          <div
            key={language.value}
            className={`language ${language.value === "gr" ? "gr" : "en"}`}
            onClick={() => handleLanguageClick(language)}
          >
            <img src={language.image} alt="" />
            <p>{language.label}</p>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Navbar;
