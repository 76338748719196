import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMsal, useIsAuthenticated, useAccount } from "@azure/msal-react";

// import GoogleLoginButton from "./GoogleLoginButton";
// import AzureLoginButton from "./AzureLoginButton";

import "./RegisterPanel.css";

const RegisterPanel = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(instance.getActiveAccount());

  // State to store form data
  const [formData, setFormData] = useState({
    givenName: "",
    surname: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
  });
  const [error, setError] = useState("");

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Basic validation for matching passwords
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
    } else if (
      !formData.givenName ||
      !formData.surname ||
      !formData.email ||
      !formData.password ||
      !formData.phone
    ) {
      setError("Please fill in all fields");
    } else {
      setError("");
      console.log("Signing up:", formData);
      // Place your signup logic here, e.g., API call
    }
  };

  const handleLogout = () => {
    instance.logoutPopup();
  };

  // TODO remove in production
  if (isAuthenticated) {
    console.log(account);
  }

  return (
    <div className="login-panel">
      <svg className="login-svg">
        <defs>
          <filter id="f1" x="-10%" y="-10%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="15" />
          </filter>
          <filter id="f2" x="-10%" y="-10%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="25" />
          </filter>
        </defs>
        <circle
          cx="12%"
          cy="194%"
          r="1000"
          stroke="#dfc40320"
          strokeWidth={80}
          fill="none"
          filter="url(#f1)"
        />
      </svg>
      {isAuthenticated ? (
        <div className="login-box authenticated">
          <h1>Welcome, {account ? account.name : "User"}!</h1>
          <p>
            Logged in as <b>{account ? account.username : "null"}</b>
          </p>
          <button className="logout" onClick={handleLogout}>
            Sign Out
          </button>
        </div>
      ) : (
        <div className="login-box">
          <h1>Sign Up</h1>
          {/* <GoogleLoginButton />
          <AzureLoginButton /> */}
          {error && <p className="error">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="givenName">Given Name</label>
              <input
                type="text"
                id="givenName"
                name="givenName"
                value={formData.givenName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="surname">Surname</label>
              <input
                type="text"
                id="surname"
                name="surname"
                value={formData.surname}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
            </div>
            <button type="submit">Sign Up</button>
          </form>
          <p className="login-link">
            Already have an account? <Link to="/login">Login here</Link>
          </p>
        </div>
      )}
    </div>
  );
};

export default RegisterPanel;
